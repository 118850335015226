<template>
  <content-layout :loading="loading" :breadcrumbs-items="breadcrumbsItems">
    <v-tabs v-model="tab">
      <v-tab
        v-for="item in tabs"
        :key="item.text"
        :disabled="submissionsTable.loading"
      >
        {{ item.text }}
      </v-tab>
    </v-tabs>
    <cz-data-table
      class="mt-10 row-pointer"
      height="calc(100vh - 320px)"
      v-bind="submissionsTable"
      :options.sync="submissionsTable.options"
      :columns="filterableColumns"
      @click:row="onSubmissionClicked"
    >
      <template #item.issueDate="{ item }">
        <span>{{ formatDateToDisplay(item.issueDate) }}</span>
      </template>
      <template #item.createdAt="{ item }">
        <span>{{ formatDateToDisplay(item.createdAt) }}</span>
      </template>
      <template #item.totalAmount="{ item }">
        {{ formatAmountWithCurrency(item.totalAmount, item.currency) }}
      </template>
      <template #item.vatAmount="{ item }">
        {{ formatAmountWithCurrency(item.vatAmount, item.currency) }}
      </template>
      <template #item.netAmount="{ item }">
        {{ formatAmountWithCurrency(item.netAmount, item.currency) }}
      </template>
      <template #item.customer="{ item }">
        <customer-info-menu
          v-if="item.customer"
          :customer-account="item.customer"
        />
      </template>
      <template #item.relatedOrder="{ item }">
        <order-info-menu v-if="item.relatedOrder" :order="item.relatedOrder" />
      </template>
      <template #item.returnType="{ item }">
        <span>{{ item.returnTypeText }}</span>
      </template>
      <template #item.rejectType="{ item }">
        <span>{{ item.rejectTypeText }}</span>
      </template>
      <template #item.actions="{ item }">
        <cz-icon :size="26" :src="mdiChevronLeft" color="textPrimary" />
      </template>
    </cz-data-table>
  </content-layout>
</template>

<script>
import { getOrderSubmissions } from '@/core/api';
import { mapFields } from 'vuex-map-fields';
import { CzDataTable, CzIcon } from '@/components';
import { formatDateToDisplay } from '@/shared/services/dates.service';
import { formatAmountWithCurrency } from '@/shared/services/currency.service';
import { mapActions } from 'vuex';
import { mdiChevronLeft } from '@mdi/js';
import cloneDeep from 'lodash.clonedeep';
import OrderInfoMenu from '@/domain/customer/components/OrderInfoMenu.vue';
import CustomerInfoMenu from '@/domain/customer/components/CustomerInfoMenu.vue';

export default {
  name: 'VendorSubmissionsPage',
  components: {
    ContentLayout: () => import('@/layouts/ContentLayout.vue'),
    CzDataTable,
    OrderInfoMenu,
    CzIcon,
    CustomerInfoMenu
  },
  props: {
    selectedTab: {
      type: Number,
      default: 0
    }
  },
  created() {
    this.tab = this.selectedTab || 0;
    // if (this.tab === 0) {
    //   this.loadData();
    // }
  },
  computed: {
    ...mapFields('vendor', ['vendor']),
    ...mapFields('auth', ['accountId']),
    breadcrumbsItems() {
      return [
        {
          text: this.$t('common.homepage'),
          to: {
            name: 'vendor-home',
            params: {
              id: this.vendor?._id
            }
          },
          disabled: false,
          exact: true,
          isHome: true
        },
        {
          text: this.$t('vendor.orderSubmissions.submissionsTitle'),
          disabled: true,
          exact: true
        }
      ];
    },
    currentTab() {
      return this.tabs[this.tab];
    },
    currentStatuses() {
      return this.currentTab.status;
    },
    actions() {
      return this.currentTab.actions;
    },
    filterableColumns() {
      const columns = cloneDeep(this.submissionsTable.columns);
      if (!this.currentTab.excludeColumns?.length) {
        return columns;
      } else {
        return columns.filter(
          (col) => !this.currentTab.excludeColumns.includes(col.value)
        );
      }
    }
  },
  data() {
    return {
      mdiChevronLeft,
      tab: 0,
      loading: false,
      selectedOrder: null,
      selectedSubmission: null,
      dataLoaded: false,
      submissionsTable: {
        loading: false,
        options: {},
        serverItemsLength: -1,
        columns: [
          {
            text: this.$t('vendor.orders.invoiceNumber'),
            value: 'documentNumber',
            sortable: true,
            width: 150
          },
          {
            text: this.$t('customer.vendorSubmissions.returnType'),
            value: 'returnType',
            sortable: false
          },
          {
            text: this.$t('customer.vendorSubmissions.rejectType'),
            value: 'rejectType',
            sortable: false
          },
          {
            text: this.$t('vendor.orderSubmissions.customerName'),
            value: 'customer',
            sortable: false,
            width: 250
          },
          {
            text: this.$t('vendor.orderSubmissions.netAmount'),
            value: 'netAmount',
            sortable: false
          },
          {
            text: this.$t('vendor.orderSubmissions.vatAmount'),
            value: 'vatAmount',
            sortable: false
          },
          {
            text: this.$t('vendor.orderSubmissions.totalAmount'),
            value: 'totalAmount',
            sortable: false
          },
          {
            text: this.$t('vendor.orderSubmissions.creationDate'),
            value: 'createdAt',
            sortable: true
          },
          {
            text: this.$t('vendor.orderSubmissions.issueDate'),
            value: 'issueDate',
            sortable: true
          },
          {
            text: this.$t('vendor.orderSubmissions.relatedOrder'),
            value: 'relatedOrder',
            sortable: false
          },
          {
            text: '',
            value: 'actions',
            sortable: false,
            width: 100,
            align: 'center'
          }
        ],
        items: []
      },
      tabs: [
        {
          id: 'in-progress',
          text: this.$t('vendor.orderSubmissions.inProgressSubmissions'),
          excludeColumns: ['returnType', 'rejectType'],
          status: ['new', 'new_predicted'],
          actions: []
        },
        {
          id: 'returned',
          text: this.$t('vendor.orderSubmissions.returnedSubmissions'),
          excludeColumns: ['rejectType'],
          status: ['returned'],
          actions: [
            // {
            //   id: 'edit',
            //   icon: mdiFileEditOutline,
            //   handler: this.onEditSubmission,
            //   tooltip: this.$t('vendor.orderSubmissions.editSubmissionTooltip'),
            //   color: 'textPrimary'
            // }
          ]
        },
        {
          id: 'pending',
          text: this.$t('vendor.orderSubmissions.pendingSubmissions'),
          excludeColumns: ['returnType', 'rejectType'],
          status: [
            'pending_customer_autorizer',
            'pending_customer_purchaser',
            'pending_approval'
          ],
          actions: []
        },
        {
          id: 'approved',
          text: this.$t('vendor.orderSubmissions.approvedSubmissions'),
          excludeColumns: ['returnType', 'rejectType'],
          status: ['approved'],
          actions: []
        },
        {
          id: 'rejected',
          text: this.$t('vendor.orderSubmissions.rejectedSubmissions'),
          excludeColumns: ['returnType'],
          status: ['rejected'],
          actions: []
        }
      ]
    };
  },
  methods: {
    ...mapActions('ui', ['showSuccessToastMessage', 'showErrorToastMessage']),
    formatAmountWithCurrency,
    formatDateToDisplay,
    onSubmissionClicked(item) {
      this.$router.push({
        name: 'vendor-submission',
        params: {
          submissionId: item._id
        }
      });
    },
    async loadData() {
      try {
        this.submissionsTable.items = [];
        this.submissionsTable.loading = true;
        const { itemsPerPage, page, sortBy, sortDesc } =
          this.submissionsTable.options;

        const $skip = (page - 1) * itemsPerPage;

        const query = {
          status: { $in: this.currentStatuses },
          accountId: this.accountId,
          vendorId: this.vendor._id,
          $populate: ['customer'],
          $limit: itemsPerPage,
          $skip
        };

        if (sortBy?.length) {
          query.$sort = {
            [`${sortBy[0]}`]: sortDesc[0] === true ? -1 : 1
          };
        }

        const { data, total } = await getOrderSubmissions(query);
        this.dataLoaded = true;

        this.submissionsTable.serverItemsLength = total;
        this.submissionsTable.items = data;
      } finally {
        this.submissionsTable.loading = false;
      }
    },
    customerMenuItems(item) {
      if (!item.customer) {
        return [];
      }

      return [
        {
          title: this.$t('vendor.orderSubmissions.customerName'),
          subtitle: item.customer.companyName
        },
        {
          title: this.$t('vendor.orderSubmissions.businessId'),
          subtitle: item.customer.businessId
        },
        {
          title: this.$t('vendor.orderSubmissions.contactPersonName'),
          subtitle: item.customer.contactPersonName
        },
        {
          title: this.$t('vendor.orderSubmissions.contactPersonEmail'),
          subtitle: item.customer.contactPersonEmail
        },
        {
          title: this.$t('vendor.orderSubmissions.contactPersonPhone'),
          subtitle: item.customer.contactPersonPhone
        }
      ];
    }
  },
  watch: {
    vendor: {
      handler() {
        this.loadData();
      }
    },
    tab: {
      // immediate: true,
      handler() {
        this.loadData();
      }
    },
    'submissionsTable.options': {
      handler() {
        this.loadData();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
// ::v-deep tbody {
//   tr:hover {
//     background-color: transparent !important;
//   }
// }
::v-deep tbody tr :hover {
  cursor: pointer;
}
</style>
